import store from "@/state/store";

export default [
  //LOGIN ROUTE

  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },

  //REGISTER ROUTE

  {
    path: "/register",
    name: "register",
    component: () => import("../views/pages/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },

  //RESET PASSWORD ROUTE

  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: "home" } : { ...routeFrom });
      },
    },
  },

  //DASHBOARD ROUTE

  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/dashboard/index"),
  },
  
  //Şifre Sıfırlama ROUTE

  {
    path: "/profile/password",
    name: "password",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/account/password"),
  },

  //RESERVATION ROUTE

  {
    path: "/reservation",
    name: "reservation",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/reservation/index"),
  },
  {
    path: "/reservation/add",
    name: "rAdd",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/reservation/add"),
  },
  {
    path: "/reservation/edit/:id",
    name: "rEdit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/reservation/edit"),
  },
  {
    path: "/reservation/voucher/:id",
    name: "voucher",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/voucher/index"),
  },
  {
    path: "/reservation/ticket/:id",
    name: "ticket",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/ticket/index"),
  },
  {
    path: "/reservation/voucher/n/:id",
    name: "nVoucher",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/voucher/fiyatsizVoucher"),
  },
  {
    path: "/reservation/tourlist/:id",
    name: "tourlist",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/voucher/tour"),
  },
  {
    path: "/reservation/tourlist/n/:id",
    name: "nTourlist",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/voucher/nTour"),
  },
  {
    path: "/accounting/payments",
    name: "payments",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/reservation/payments"),
  },
  //HOTEL ROUTE

  {
    path: "/hotel",
    name: "hotel",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/hotel/index"),
  },
  {
    path: "/hotel/add",
    name: "addHotel",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/hotel/addHotel"),
  },
  {
    path: "/hotel/edit/:id",
    name: "editHotel",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/hotel/editHotel"),
  },
  //ROOM GOOD ROUTE
  {
    path: "/room",
    name: "room",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/room/index"),
  },
  {
    path: "/room/add",
    name: "roAdd",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/room/add"),
  },
  {
    path: "/room/edit/:id",
    name: "roEdit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/room/edit"),
  },

  //CUSTOMER ROUTE

  {
    path: "/customer",
    name: "customer",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/index"),
  },
  {
    path: "/customer/add",
    name: "addCustomer",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/addCustomer"),
  },
  {
    path: "/customer/edit/:id",
    name: "editCustomer",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/customer/editCustomer"),
  },

  //AGENCY ROUTE

  {
    path: "/agency",
    name: "agency",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/agency/index"),
  },
  {
    path: "/agency/add",
    name: "addAgency",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/agency/addAgency"),
  },
  {
    path: "/agency/edit/:id",
    name: "editAgency",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/agency/editAgency"),
  },

  // CİTY ROUTE

  {
    path: "/city",
    name: "city",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/city/index"),
  },
  {
    path: "/city/add",
    name: "cadd",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/city/add"),
  },
  {
    path: "/city/edit/:id",
    name: "cedit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/city/edit"),
  },

  // VEHICLE ROUTE

  {
    path: "/vehicle",
    name: "vehicle",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/vehicle/index"),
  },
  {
    path: "/vehicle/add",
    name: "vadd",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/vehicle/add"),
  },
  {
    path: "/vehicle/edit/:id",
    name: "vedit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/vehicle/edit"),
  },

  // EMPLOYEE ROUTE

  {
    path: "/employee",
    name: "employee",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/employee/index"),
  },
  {
    path: "/employee/add",
    name: "eadd",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/employee/add"),
  },
  {
    path: "/employee/edit/:id",
    name: "eedit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/employee/edit"),
  },

  // INCOME EXPENSE ROUTE

  {
    path: "/incomeexpensetype",
    name: "incomeexpensetype",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/incomeexpensetype/index"),
  },
  {
    path: "/incomeexpensetype/add",
    name: "inadd",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/incomeexpensetype/add"),
  },
  {
    path: "/incomeexpensetype/edit/:id",
    name: "inedit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/incomeexpensetype/edit"),
  },

  // EXCHANGE ROUTE

  {
    path: "/exchange",
    name: "exchange",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/exchange/index"),
  },
  {
    path: "/exchange/add",
    name: "exadd",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/exchange/add"),
  },
  {
    path: "/exchange/edit/:id",
    name: "exedit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/exchange/edit"),
  },

  // EXCHANGE ROUTE

  {
    path: "/banks",
    name: "banks",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/banks/index"),
  },
  {
    path: "/banks/add",
    name: "banadd",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/banks/add"),
  },
  {
    path: "/banks/edit/:id",
    name: "banedit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/banks/edit"),
  },
  // Expense ROUTE
  {
    path: "/expense",
    name: "expense",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/expense/index"),
  },
  {
    path: "/expense/add",
    name: "expadd",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/expense/add"),
  },
  {
    path: "/expense/edit/:id",
    name: "expedit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/expense/edit"),
  },
  // Expense ROUTE
  {
    path: "/revenue",
    name: "revenue",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/revenue/index"),
  },
  {
    path: "/revenue/add",
    name: "revadd",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/revenue/add"),
  },
  {
    path: "/revenue/edit/:id",
    name: "revedit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/revenue/edit"),
  },
  // Report Route
  {
    path: "/reports",
    name: "reports",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/reports/index"),
  },
  {
    path: "/reports/result",
    name: "result",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/reports/result"),
  },

  // Report Route
  {
    path: "/users",
    name: "users",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user/index"),
  },
  {
    path: "/users/add",
    name: "usadd",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user/add"),
  },
  {
    path: "/user/edit/:id",
    name: "usedit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/user/edit"),
  },
  // Report Route
  {
    path: "/current-account",
    name: "current-account",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/current-account/index"),
  },
  {
    path: "/current-account/add",
    name: "current-account-add",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/current-account/add"),
  },
  {
    path: "/current-account/edit/:id",
    name: "current-account-edit",
    meta: {
      authRequired: true,
    },
    component: () => import("../views/pages/current-account/edit"),
  },
];
